/* CSS files add styling rules to your content */

/* body, html {
  font-family: helvetica, arial, sans-serif;
  margin: 0px;
  background: linear-gradient(#ff3267, #00fff0);
  width: 100%;
  height: 100%;
  padding: 20px;
  font-style: italic;
} */

body, html {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* text-shadow: yellow 3px 3px 3px;
  -moz-box-shadow:    inset 10px 0 60px #969;
   -webkit-box-shadow: inset 10px 0 60px #969;
   box-shadow:         inset 10px 0 60px #969; */
/* background: #f7fbfc; /* Old browsers */
/* background: -moz-linear-gradient(-45deg, #f7fbfc 0%, #eaead3 40%, #f4bac3 100%); /* FF3.6-15 */ 
/* background: -webkit-linear-gradient(-45deg, #f7fbfc 0%,#eaead3 40%,#f4bac3 100%); /* Chrome10-25,Safari5.1-6 */ 
/* background: linear-gradient(135deg, #f7fbfc 0%,#eaead3 40%,#f4bac3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ 
}

/* .gradient {
  background: #f5f6f6;
background: -moz-linear-gradient(top, #f5f6f6 0%, #dbdce2 21%, #b8bac6 49%, #dddfe3 80%, #f5f6f6 100%);
background: -webkit-linear-gradient(top, #f5f6f6 0%,#dbdce2 21%,#b8bac6 49%,#dddfe3 80%,#f5f6f6 100%);
background: linear-gradient(to bottom, #f5f6f6 0%,#dbdce2 21%,#b8bac6 49%,#dddfe3 80%,#f5f6f6 100%);
} */

/* prism style overrides */
.token.class-name, .token.function {
  color: black;
}

.token.operator {
  background: none;
}

.gradient{
background: #f7fbfc; /* Old browsers
background: -moz-linear-gradient(-45deg, #f7fbfc 0%, #eaead3 40%, #f4bac3 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, #f7fbfc 0%,#eaead3 40%,#f4bac3 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, #f7fbfc 0%,#eaead3 40%,#f4bac3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.frame {
  border-style: inset;
  border-width: 10px;
}

h1 {
  font-style: italic;
  color: #373fff;
}

.animated-positions {
  transition: width 1s, height 1s, left 1s, top 1s;
}

iframe {
  width: 200px;
  height: 200px;
  border-width: 10px;
  /* display: inline-block;
/*    border: solid 10px black; */
  /* margin: 15px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.text-shadow {
  text-shadow:
    -1px -1px 2 #f0d,
    1px -1px 2 #f0d,
    -1px 1px 2 #f0d,
    1px 1px 2 #f0d;
}

.main-heading {
  position: fixed;
  top: 20px;
  text-align: right;
  right: 20px;
  font-size: 3rem;
  line-height: 0.65;
  max-width: 30rem;
  text-shadow:
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.marquee {
   display: inline-block;
   animation: marquee 10s linear infinite;
}

.marquee2 {
   display: inline-block;
  animation: marquee2 10s linear infinite;
 animation-delay: 5s;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}
